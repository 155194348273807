<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
    	<v-col cols="12" md="6" lg="6">
	      <v-card class="elevation-0 transparent">
	        <v-img src="@/assets/banner1.png" contain aspect-ratio="1"/>
	      </v-card>
    	</v-col>

    	<v-col cols="12" md="6" lg="6">
	      <v-card class="elevation-0 transparent">
	        <v-card-text class="black--text">
	          <ol class="text-h6">
	          	<li class="mb-10">BRINDAR APOYO ES NUESTRA MEJOR VIRTUD.</li>
							<li class="mb-10">CONFIANZA Y RESPETO A TODOS LOS QUIMICOS, BIOLOGOS, DOCTORES, INGENIEROS, LICENCIADOS, INVESTIGADORES, ETC…</li>
							<li class="mb-10">QUE HAN ADQUIRIDO DE NUESTROS SERVICIOS.</li>
							<li class="mb-10">SIEMPRE DISPUESTOS A RESOLVER SUS DUDAS.</li>
							<li class="mb-10">INNOVALAB PARA UNA MEJOR SOLUCION.</li>
	          </ol>
	        </v-card-text>		
	      </v-card>
    	</v-col>

    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'
  import {mapActions , mapGetters} from 'vuex'
	import metodos from '@/mixins/metodos.js';
  import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })

  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],

    components: {
      card_articulo,
    },

    data (){
      return {
        marcas:[],
        url   : this.$http.options.root + 'fotos-fetish/' ,
      }
    },

    created(){
      this.init();
      console.log(this.seguimiento)
    },

    computed:{
      ...mapGetters('Listado',['Loading','listado_productos','seguimiento']),
      ...mapGetters('login'  ,['getusuarioFetish']),
    },

    methods:{

      init(){
      	this.marcas = []

				this.$http.get('marcas.list').then( response =>{
					this.marcas = response.body
					console.log( this.marcas)
				}).catch( error =>{
					this.validarError(error )
				})
      },
    }
  }
</script>

